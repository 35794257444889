import LogoImg from '../static/img/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';

export function FooterLink(props)
{
    const current = useLocation().pathname;

    return (
        <p><a href={props.link} className={current == props.link ? 'active' : ''}>{props.title}</a></p>
    );
}

export default function Footer() {

    return (
        <div className='footer'>
            <div className='contact'>
                <div className='company'>
                    <div className='section'>
                        <div>
                            <img src={LogoImg} alt="logo"/>
                        </div>
                        <div>
                            <p>Level 10, 420 George Street,</p>
                            <p>Sydney, NSW 2000, Australia</p>
                        </div>
                        <div>
                            <p>admin@miepay.com</p>
                        </div>
                        <div>
                            <p>+61-2-8034-0470</p>
                        </div>

                        <div className='linked-in-background'>
                            <a href='https://cn.linkedin.com/company/mypay-new-zealand-limited' target="_blank"><FontAwesomeIcon icon="fa-brands fa-linkedin" className='linked-in'/></a>
                        </div>
                    </div>

                    <div className='content section'>
                        <div className='pages'>
                            <label>COMPANY</label>
                            <FooterLink title='Home' link="/"/>
                            <FooterLink title='About Us' link="/aboutUs"/>
                            <FooterLink title='News' link="/news"/>
                            <FooterLink title='Investor Relations' link="/investor-relations"/>
                            <FooterLink title='Contact us' link="/contact-us"/>
                            <FooterLink title='Terms and Conditions' link="/terms-and-conditions"/>
                        </div>

                        <div className='products'>
                            <label>SERVICES & PRODUCTS</label>
                            <FooterLink title='IE PAY' link="/services-products/ie-pay"/>
                            <FooterLink title='IE MALL' link="/services-products/ie-mall"/>
                            <FooterLink title='Smart Box' link="/services-products/smart-box"/>

                        </div>
                    </div>
                </div>
            </div>

            <div className="copy_right">
                <p>Copyright © 2024. All Rights Reserved.</p>
            </div>
        </div>
    );
  }
  